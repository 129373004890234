
import { defineComponent } from 'vue';

//import Swal from "sweetalert2/dist/sweetalert2.min.js";
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import * as Yup from 'yup';
import ApiService from '@/core/services/ApiService';
// import TableHeaderChange from "@/layout/header/partials/trainning_institute/TableHeaderChange.vue";
import { Form, Field, ErrorMessage } from 'vee-validate';
import Multiselect from '@vueform/multiselect';
import { apiEndpoint } from '@/mixin/apiEndpoint';
import { VueCookieNext } from 'vue-cookie-next';
export default defineComponent({
    mixins: [apiEndpoint],
    name: 'trainning-institute-dynamicreport',
    components: {
        Datatable,
        Multiselect,
        Form,
        Field,
        ErrorMessage,
        // TableHeaderChange,
    },

    data() {
        return {
            selectedDistricts: [] as any,
            value: null,

            options: [],
            batch: {},
            employee: {},
            details: {},
            selected: {},
            weekplan: {},
            entityTypes: [],
            industry: [],
            entityInfos: [],
            divisions: [],
            districts: [],
            subDistricts: [],
            empDistricts: [],
            selectedEntity: [],
            selectedTrance: [],
            selectedCourseType: [],
            selectedCourse: [],
            selectedDistrict: [],
            updatedArray: [] as any,
            tableData: [] as any,

            tableHeader: [
                {
                    name: 'SL',
                    key: 'sl',
                    sortable: true,
                    width: '5px',
                    selected: true,
                },
                {
                    name: 'Photo',
                    key: 'photo',
                    sortable: true,
                    width: '5px',
                },
                 {
                    name: 'Assessor Number',
                    key: 'assessor_num',
                    sortable: true,
                    selected: true,
                    width: '150px',
                },
                 {
                    name: 'Assessor Name',
                    key: 'name',
                    sortable: true,
                    selected: true,
                },
                 {
                    name: ' Entity',
                    key: 'entity',
                    sortable: true,
                    selected: true,
                    width: '50px',
                },
                 {
                    name: 'Tranche',
                    key: 'tranche',
                    sortable: true,
                },
                {
                    name: 'Training Institute',
                    key: 'tInstitute',
                    sortable: true,
                    selected: true,
                },
                {
                    name: 'Course',
                    key: 'course',
                    sortable: true,
                    width: '200px',
                },
                {
                    name: 'Birth Date',
                    key: 'birth_date',
                    sortable: true,
                },

                {
                    name: 'Nid',
                    key: 'nid',
                    sortable: true,
                },
               
                {
                    name: 'Gender',
                    key: 'gender',
                    sortable: true,
                },
                {
                    name: 'Religion',
                    key: 'religion',
                    sortable: true,
                },
                {
                    name: 'Nationality',
                    key: 'nationality',
                    sortable: true,
                },
                {
                    name: 'Mobile',
                    key: 'mobile',
                    sortable: true,
                },
                {
                    name: 'Email',
                    key: 'email',
                    sortable: true,
                },
                {
                    name: "Highest Level Of Education",
                    key: "education",
                    sortable: true,
                },
                {
                    name: "Years Of Exp",
                    key: "experience",
                    sortable: true,
                },
                {
                    name: 'Area Of Expertise',
                    key: 'expertise',
                    sortable: true,
                },
                {
                    name: ' List Of Certification',
                    key: 'certification',
                    sortable: true,
                },
               
                {
                    name: 'Active Status',
                    key: 'active_status',
                    sortable: true,
                },
            ] as any,

            districtData: [] as any,
            TpartnerData: [] as any,
            trainingTypeData: [] as any,
            trainingProgramData: [] as any,
            trainingLocationData: [] as any,
            TranceData: [] as any,
            api_url: '',
            CourseTypeData: [] as any,
            CourseListData: [] as any,
            TInstituteData: [] as any,
            TpartnerLabel: [] as any,
            TrancheLabel: [] as any,
            CourseLabel: [] as any,
            CourseListLabel: [] as any,
            TinstituteLabel: [] as any,
            TProgrameLabel: [] as any,
            TtypeLabel: [] as any,
            TlocationLabel: [] as any,
            districtInfos: [] as any,
            TpartnerInfos: [] as any,
            TrancheInfos: [] as any,
            CourseTypeInfos: [] as any,
            CourseListInfos: [] as any,
            InstituteInfos: [] as any,
            optionsTPartner: [] as any,
            optionsTranche: [] as any,
            optionsCourseType: [] as any,
            optionsCourseList: [] as any,
            optionsInstituteList: [] as any,
            optionstrainningTypeList: [] as any,
            optionstrainningProgramList: [] as any,
            optionstrainningLocationList: [] as any,
            st: [] as any,
            loading: false,
            courseInfoData: false,
            showCourseNotice: false,
            showTableData: false,
            showtable: true,
            showall: false,
            discardChangeData: false,
            declinechange: false,
            componentKey: 0,
            componentTableKey: 0,
            formattedHeader: [] as any,
            formattedBody: [] as any,
            TPrograme: [] as any,
            Ttype: [] as any,
            TLocation: [] as any,
            headerSelect: false,
            load: true,
            isActiveTrainerData: 0 as any,
            isGuestTrainerData: 0 as any,
        };
    },
    async created() {
        this.api_url = this.VUE_APP_API_URL;
        await this.getAssociation();
        await this.getTranche();
        await this.getTrainingPrograme();
        await this.getTrainingType();
        await this.getCourseTypeList();
        await this.getTrainingLocation();
        // this.optionstrainningTypeList.push(
        //   { value: 1, label: 'Management' },
        //   { value: 2, label: 'OHS' },
        //   { value: 3, label: 'Pedagogy' },
        //   { value: 4, label: 'ToT Skills' }
        // );
        // this.optionstrainningProgramList.push(
        //   { value: 1, label: 'Management Training Local' },
        //   { value: 2, label: 'Management Training Overseas' },
        //   { value: 3, label: ' OHS Training Local' },
        //   { value: 4, label: 'OHS Training Overseas' },
        //   { value: 5, label: 'Pedagogy Training Local' },
        //   { value: 6, label: 'ToT Skills Training Local' },
        //   { value: 7, label: 'ToT Skills Training Overseas' }
        // );
        // this.optionstrainningLocationList.push(
        //   { value: 1, label: 'Home' },
        //   { value: 2, label: 'ITEES, Singapore' },
        //   { value: 3, label: 'NYPi, Singapore' }
        // );

        // await this.getallCourse();
    },
     watch: {
        "TpartnerData": {
            handler: "changePartnerData", // Call the method when batch.entity_id changes
            immediate: false, // Set this to false if you want to trigger on initial mount
         },
         "TInstituteData": {
            handler: "changeTinstituteData", // Call the method when batch.entity_id changes
            immediate: false, // Set this to false if you want to trigger on initial mount
        },
    },


    methods: {
        isguestTrainerCheck(event) {
            if (event.target.checked) {
                this.isGuestTrainerData = 1;
                console.log(this.isGuestTrainerData);
            } else {
                this.isGuestTrainerData = 0;
                console.log(this.isGuestTrainerData);
            }
        },
        isActiveTrainer(event) {
            if (event.target.checked) {
                this.isActiveTrainerData = 1;
                console.log(this.isActiveTrainerData);
            } else {
                this.isActiveTrainerData = 0;
                console.log(this.isActiveTrainerData);
            }
        },
        resetData() {
            (this.TProgrameLabel = [] as any),
                (this.TtypeLabel = [] as any),
                (this.TlocationLabel = [] as any),
                (this.tableData = [] as any);
            this.TinstituteLabel = [] as any;
            this.TranceData = [] as any;
            this.TpartnerData = [] as any;
            this.CourseTypeData = [] as any;
            this.CourseListData = [] as any;
            this.TpartnerLabel = [] as any;
            this.TrancheLabel = [] as any;
            this.CourseLabel = [] as any;
            this.CourseListLabel = [] as any;
            this.st = [] as any;
            this.showall = false;
            this.showTableData = false;
            this.componentTableKey += 1;
        },
        // downloadPdf() {
        //   const doc = new jsPDF();
        //   for (let i = 0; i < this.tableHeader.length; i++) {
        //     if (this.tableHeader[i].selected) {
        //       this.formattedHeader.push(this.tableHeader[i].name);
        //       // console.log(this.st);
        //     }
        //   }
        //   for (let i = 0; i < this.tableData.length; i++) {
        //     if (this.tableHeader[i].selected) {
        //       this.formattedHeader.push(this.tableHeader[i].name);
        //       // console.log(this.st);
        //     }
        //   }

        //   autoTable(doc, {
        //     styles: { cellWidth: "auto" },
        //     head: [this.formattedHeader],
        //     body: [["trance", "entity", "trance2", "entity23"]],
        //   });

        //   // autoTable(doc, {
        //   //   html: this.$refs.datatable as any,
        //   // });

        //   doc.save("table.pdf");
        // },
        async getCourseTypeList() {
            this.load = true;
            await ApiService.get('configurations/course_type/list')
                .then((response) => {
                    this.CourseTypeInfos = response.data.data;
                    this.CourseTypeInfos.forEach((item, index) => {
                        let obj = {
                            value: item.id,
                            label: item.type_name,
                        };
                        this.optionsCourseType.push(obj);
                    });
                    this.load = false;
                })
                .catch(({ response }) => {
                    console.log(response);
                });
        },

        async getAssociation() {
            let entity_id = '';
            if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
                entity_id = VueCookieNext.getCookie('_entity_id');
            }
            this.load = true;
            await ApiService.get('entity/list?entity_id=' + entity_id)
                .then((response) => {
                    this.TpartnerInfos = response.data.data;
                    this.TpartnerInfos.forEach((item, index) => {
                        let obj = {
                            value: item.id,
                            label: item.entity_short_name,
                        };
                        this.optionsTPartner.push(obj);
                    });
                    this.load = false;
                })
                .catch(({ response }) => { });
        },
        async getTranche() {
            this.load = true;
            await ApiService.get('configurations/tranche/list')
                .then((response) => {
                    this.TrancheInfos = response.data.data;
                    this.TrancheInfos.forEach((item, index) => {
                        let obj = {
                            value: item.id,
                            label: item.label,
                        };
                        this.optionsTranche.push(obj);
                    });
                    this.load = false;
                })
                .catch(({ response }) => {
                    console.log(response);
                });
        },
        async getTrainingPrograme() {
            this.load = true;
            await ApiService.get('configurations/training-name/list')
                .then((response) => {
                    this.TPrograme = response.data.data;
                    this.TPrograme.forEach((item, index) => {
                        let obj = {
                            value: item.id,
                            label: item.training_name,
                        };
                        this.optionstrainningProgramList.push(obj);
                    });
                    this.load = false;
                })
                .catch(({ response }) => {
                    console.log(response);
                });
        },
        async getTrainingType() {
            this.load = true;
            await ApiService.get('configurations/training-type/list')
                .then((response) => {
                    this.Ttype = response.data.data;
                    this.Ttype.forEach((item, index) => {
                        let obj = {
                            value: item.id,
                            label: item.training_type,
                        };
                        this.optionstrainningTypeList.push(obj);
                    });
                    this.load = false;
                })
                .catch(({ response }) => {
                    console.log(response);
                });
        },
        async getTrainingLocation() {
            this.load = true;
            await ApiService.get('configurations/training-location')
                .then((response) => {
                    this.TLocation = response.data.data;
                    this.TLocation.forEach((item, index) => {
                        let obj = {
                            value: item.training_location,
                            label: item.training_location,
                        };
                        this.optionstrainningLocationList.push(obj);
                    });
                    this.load = false;
                })
                .catch(({ response }) => {
                    console.log(response);
                });
        },
        changeTinstituteData(data) {
            this.getCourseListData();
            this.TinstituteLabel = [];
            data.map((item, index) => {
                this.optionsInstituteList.forEach((item2, index2) => {
                    if (item2.value == item) {
                        this.TinstituteLabel.push(item2.label);
                    }
                });
            });
        },
        changePartnerData(data) {
            this.getInstituteData();
            // this.getCourseListData();
            this.TpartnerLabel = [];
            data.map((item, index) => {
                this.optionsTPartner.forEach((item2, index2) => {
                    if (item2.value == item) {
                        this.TpartnerLabel.push(item2.label);
                    }
                });
            });
        },
        changeTrancheData(data) {
            // this.getCourseListData();

            this.TrancheLabel = [];
            data.map((item, index) => {
                this.optionsTranche.forEach((item2, index2) => {
                    if (item2.value == item) {
                        this.TrancheLabel.push(item2.label);
                    }
                });
                console.log(this.TrancheLabel);
            });
        },
        changeCourseTypeData(data) {
            this.getCourseListData();

            this.CourseLabel = [];
            // console.log(this.optionsCourseType);
            data.map((item, index) => {
                this.optionsCourseType.forEach((item2, index2) => {
                    if (item2.value == item) {
                        this.CourseLabel.push(item2.label);
                    }
                });
            });
            // console.log(this.CourseLabel);
        },
        async getCourseListData() {
            // console.log(this.TpartnerData, this.TranceData, this.CourseTypeData);

            await ApiService.get(
                'course/filterCourse?entity=' +
                this.TpartnerData +
                '&tranche=' +
                this.TranceData +
                '&courseType=' +
                this.CourseTypeData
            )
                .then((response) => {
                    this.CourseListInfos = [] as any;
                    this.CourseListInfos = response.data.data;
                    this.optionsCourseList = [] as any;
                    this.CourseListInfos.forEach((item, index) => {
                        let obj = {
                            value: item.id,
                            label: `${item.code} - ${item.course_name}`,
                        };

                        this.optionsCourseList.push(obj);
                    });
                })
                .catch(({ response }) => {
                    console.log(response);
                });
        },
        async getInstituteData() {
            // console.log(this.TpartnerData, this.TranceData, this.CourseTypeData);
            let institute_id = '';
            if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
                institute_id = VueCookieNext.getCookie('_entity_id');
            }
            await ApiService.get(
                'institute/list?entity_id=' +
                this.TpartnerData +
                '&institute_info_id=' +
                institute_id
            )
                .then((response) => {
                    this.InstituteInfos = [] as any;
                    this.InstituteInfos = response.data.data;
                    // console.log(this.InstituteInfos);
                    this.optionsInstituteList = [] as any;
                    this.InstituteInfos.forEach((item, index) => {
                        let obj = {
                            value: item.id,
                            label: `${item.institute_name}`,
                        };
                        this.optionsInstituteList.push(obj);
                    });
                })
                .catch(({ response }) => {
                    console.log(response);
                });
        },

        changeCourseListData(data) {
            this.CourseListLabel = [];
            // console.log(this.optionsCourseType);
            data.map((item, index) => {
                this.optionsCourseList.forEach((item2, index2) => {
                    if (item2.value == item) {
                        this.CourseListLabel.push(item2.label);
                    }
                });
            });
            // console.log(this.CourseLabel);
        },
        changeTrainngTypeData(data) {
            this.TtypeLabel = [];
            // console.log(this.optionsCourseType);
            data.map((item, index) => {
                this.optionstrainningTypeList.forEach((item2, index2) => {
                    if (item2.value == item) {
                        this.TtypeLabel.push(item2.label);
                    }
                });
            });
            // console.log(this.CourseLabel);
        },
        changeTrainngProgrameData(data) {
            this.TProgrameLabel = [];
            // console.log(this.optionsCourseType);
            data.map((item, index) => {
                this.optionstrainningProgramList.forEach((item2, index2) => {
                    if (item2.value == item) {
                        this.TProgrameLabel.push(item2.label);
                    }
                });
            });
            // console.log(this.CourseLabel);
        },
        changeTrainngLocationData(data) {
            this.TlocationLabel = [];
            // console.log(this.optionsCourseType);
            data.map((item, index) => {
                this.optionstrainningLocationList.forEach((item2, index2) => {
                    if (item2.value == item) {
                        this.TlocationLabel.push(item2.label);
                    }
                });
            });
            // console.log(this.CourseLabel);
        },

        getHeaderChangeData(value) {
            console.log(value);
        },

        async generateTrainerReport() {
            this.load = true;
            await ApiService.get(
                'entity/assessor/filter?entity=' +
                this.TpartnerData +
                '&tranche=' +
                this.TranceData +
                '&institute=' +
                this.TInstituteData +
                '&course=' +
                this.CourseListData +
                '&isactive=' +
                this.isActiveTrainerData
            )
                .then((response) => {
                    this.headerSelect = true;
                    this.selectedTableHeader();
                    this.tableData = response.data.data;

                    this.componentTableKey += 1;
                    this.load = false;
                })
                .then((response) => {
                    console.log(response);
                    this.load = false;
                });
        },
        async getEntityInfos() {
            await ApiService.get('entity/infos')
                .then((response) => {
                    this.entityInfos = response.data;
                })
                .catch(({ response }) => {
                    console.log(response);
                });
        },
        async getEntityTypes() {
            await ApiService.get('entity/types')
                .then((response) => {
                    this.entityTypes = response.data;
                })
                .catch(({ response }) => {
                    console.log(response);
                });
        },
        courseInfo() {
            this.courseInfoData = true;
        },
        headerFilter() {
            this.showCourseNotice = true;
        },

        selectedTableHeader() {
            this.st = Array();
            for (let i = 0; i < this.tableHeader.length; i++) {
                if (this.tableHeader[i].selected) {
                    this.st.push(JSON.parse(JSON.stringify(this.tableHeader[i])));
                    // console.log(this.st);
                }
            }
            this.showTableData = true;
            this.showall = true;
        },
    },
});
